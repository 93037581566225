<template>
  <div class="box my-6 mx-6">
    <div class="art-contain">
      <img src="../assets/Body.svg" class="body-svg" alt="My Happy SVG" />
      <div class="body-svg">
        <svg ref="bodyColor" xmlns="http://www.w3.org/2000/svg" width="592.052" height="391.695" viewBox="0 0 592.052 391.695"><path class="a" d="M167.465,306.011s-29.938,10.038-42.886,33.911-17.013,38.8-17.013,38.8-6.986,19.906-5.916,29.557,5.5,29.137,5.5,29.137l7.8,11.945,37.05,30.059L195.072,505.5l38.856,16.655,41.1,9.74,33.022,9,26.914,11.265,27.876,12.323,23.14,11.19,12.651,4.489s-12.505-6.144-15.182-37.069,9.82-56.808,9.82-56.808,20-34.923,29.317-43.039,30.806-20.583,36.621-19.3a81.809,81.809,0,0,0,12.738,1.507l8.035,3.126s-1.669-28.407,7.14-46.425a158.5,158.5,0,0,1,18.7-29.166c.96-1.178,1.516-1.81,1.516-1.81l15.411-11.825,17.07-3.306,42.8,17.692,16.018,10.88,9.665,17.236,1.343,13.031-6.679,26.249-10,28.874,31.677,17.1s-18.168-8.076-15.645-49.23,18.036-59.353,18.036-59.353,19.434-36.781,36.427-42.665a171.975,171.975,0,0,1,30.138-7.641l-53.471-22.936s-31.151-11.483-34.071-14.778-25.979-14.22-39.187-26.248-55.523-30.844-55.523-30.844-44.508-16.208-56.963-17.5-44.566-7.449-44.566-7.449l-17.868.986s-25.257,11.211-38.273,28.828-21.82,43.584-21.82,43.584l-3.229,13.317-6.973,6.309-5.908,1.481-4.576,5.7-5.9.761-3.607,4.516-3.98,1.536-4.637,4.726-6.729,2.029-26.172,17.24s-31.485-7.357-43.485-10.969-42.595-4.132-42.595-4.132Z" transform="translate(-101.539 -188.468)"/></svg>
      </div>
      <div class="cup-ring-svg">
<svg ref="ringColor" xmlns="http://www.w3.org/2000/svg" width="338.267" height="286.487" viewBox="0 0 338.267 286.487"><g transform="translate(-383.444 -308.427)"><path class="a" d="M496.579,437.222c-3.089-.292-42.674,5.1-67.3,51.554s-10.642,91.578-10.642,91.578l12.025,14.56-30.333-12.038s-16.755-17.587-16.883-39.782,6.654-48.88,6.654-48.88,20.408-39.429,24.83-42.69S433,434.55,438.994,431.891s20.21-7.945,20.21-7.945l16.857,3.11Z"/><path class="a" d="M721.711,321.7c-3.09-.292-42.675,5.1-67.3,51.554s-10.642,91.578-10.642,91.578l12.025,14.56L625.46,467.357s-16.755-17.587-16.882-39.782,6.653-48.88,6.653-48.88,20.408-39.429,24.83-42.69,18.073-16.974,24.064-19.633,20.21-7.945,20.21-7.945l16.857,3.11Z"/></g></svg>
      </div>
      <div class="cups-svg">
        <svg ref="cupColor" xmlns="http://www.w3.org/2000/svg" width="393.174" height="296.803" viewBox="0 0 393.174 296.803"><g transform="translate(-414.456 -323.524)"><path class="a" d="M581.688,512.823l-3.338-18.036-7.884-16.274-9.8-8.6-13.416-7.988-44.953-22.294s-32.267-6.3-48.067,16.806-34.634,32.6-37.087,62.032-3.158,23.809-2.386,34.551,4.085,33.3,7.829,36.741,15.764,9.789,15.764,9.789l49.389,18.421s11.7,3.029,18.8,2.222,27.393-.93,43.521-22.263c9.877-13.066,27.183-38.83,26.1-44.971s4.2-14.721,4.2-14.721Zm-46.723,30.912-8.637,16.152s-13.342,11.345-14.7,13.08a32.539,32.539,0,0,0-2.659,4.223l-12.464-3.1-3.383-10.259s5.534-17.931,9.075-31.481,22.122-29.133,22.122-29.133l8.994.434,4.006,8.33,1.571,16.252Z"/><path class="a" d="M807.63,397.254l-3.339-18.036-7.883-16.275-9.8-8.6-13.416-7.988-44.953-22.294s-37.924-5.346-53.725,17.762-28.976,31.638-31.429,61.076-3.158,23.809-2.386,34.551,4.085,33.3,7.829,36.741,15.764,9.789,15.764,9.789l49.39,18.421s11.686,2.948,18.8,2.222c15.77-1.611,33.594-9.236,43.521-22.263s27.183-38.83,26.095-44.971,4.2-14.721,4.2-14.721Zm-49.7,27.588L749.3,440.994s-9.83,13.125-11.191,14.859-6.172,2.445-6.172,2.445l-12.464-3.1-1.3-8.874s4.458-18.242,10.2-31.015c6-13.34,21-29.6,21-29.6l6.913-.952,4.006,8.33,1.571,16.253Z"/></g></svg>
      </div>
      <div class="focus-svg">
        <svg ref="focusColor" xmlns="http://www.w3.org/2000/svg" width="128.955" height="133.38" viewBox="0 0 128.955 133.38"><path class="a" d="M514.662,346.25s-10.239,6.6-18.855,20.017c-3.379,5.264-6.474,9.616-8.69,15.887-1.053,2.98-3.585,8.98-4.355,11.836-1,3.707-1.55,10.7-2.156,14.032-2.42,13.3-.629,20.557-.629,20.557l60.611,30.008,3.728-.112s-6.715-13.307-7.023-19.608,5.108-31.541,7.719-35.515,11.974-19.138,17.07-21.567,16.386-10.777,22.094-9.132,16.2,3.083,17.013,17.626-2.522,29.7-6.271,36.6-15.075,24.369-19.385,28.218-13.233,7.134-13.233,7.134l-9.838,1.405,6.038,4.892,7.449,2,15.739-9.644,11.385-15.415,10.957-19.444,3.855-25.784.409-18.389-6.98-14.216-3.12-5.4-8.474-5.423-48.215-19.668-14.02,1.5-8.265,3.831Z" transform="translate(-479.339 -337.151)"/></svg>
      </div>
      <div class="icon-svg">
        <svg ref="badgeColor" xmlns="http://www.w3.org/2000/svg" width="63.586" height="90.049" viewBox="0 0 63.586 90.049"><g transform="translate(-928.843 -531.307)"><path class="a" d="M568.762,375.543s-29.832,24.9-29.626,47.86-1.433,17.088-1.433,17.088,3.712,17.114,6.613,17.984,5.948,5.721,19.35,3.447,26.714-22.294,26.714-22.294l9.938-27.913s2.184-15.05,0-24.913-5.75-11.363-5.75-11.363-7.525-1.732-10.392-2.786S568.762,375.543,568.762,375.543Z" transform="translate(391.14 158.832)"/><path class="b" d="M542.375,441.869c-.056-1.6.107-17.713.107-17.713l2.682-.906,5.4,2.2.625-5.731,4.677-2.166,1.036,1.423,6.31-6.469s3.1-.8,3.6-.954,5,1.829,5,1.829l4.875-8.068,7.482-5.375s.643,6.113,1.643,3.41,5.875-8.929,5.875-8.929l5-1.013.935,4.549-6.685,2.7,6.685.854.565,4.664a50.008,50.008,0,0,1-3.125,4.928c-.375.168-5.338,4.026-5.338,4.026s-2.287.233-2.787-.687.055-3.761.055-3.761l-3.784,8.3s-5.733,2.507-6.065,2.526a26.031,26.031,0,0,1-3.081-1.231l-2.125-2.525s-1.33,5.995-1.665,6.2-3.46,3.747-3.46,3.747l-1.731,1.133-3.144,2.7-3.75-1.8-2.323-1.916v8.145l-4.427.718-4.218-6.259-.738,10.079Z" transform="translate(391.14 158.832)"/></g></svg>
      </div>
      <div class="logo-svg">
        <svg ref="logoColor" xmlns="http://www.w3.org/2000/svg" width="56.409" height="49.182" viewBox="0 0 56.409 49.182"><path class="a" d="M542.375,441.869c-.056-1.6.107-17.713.107-17.713l2.682-.906,5.4,2.2.625-5.731,4.677-2.166,1.036,1.423,6.31-6.469s3.1-.8,3.6-.954,5,1.829,5,1.829l4.875-8.068,7.482-5.375s.643,6.113,1.643,3.41,5.875-8.929,5.875-8.929l5-1.013.935,4.549-6.685,2.7,6.685.854.565,4.664a50.008,50.008,0,0,1-3.125,4.928c-.375.168-5.338,4.026-5.338,4.026s-2.287.233-2.787-.687.055-3.761.055-3.761l-3.784,8.3s-5.733,2.507-6.065,2.526a26.031,26.031,0,0,1-3.081-1.231l-2.125-2.525s-1.33,5.995-1.665,6.2-3.46,3.747-3.46,3.747l-1.731,1.133-3.144,2.7-3.75-1.8-2.323-1.916v8.145l-4.427.718-4.218-6.259-.738,10.079Z" transform="translate(-542.077 -393.076)"/></svg>
      </div>
      <div class="overlay-svg">
        <img src="../assets/Overlay.svg?v=2" alt="My Happy SVG" />
      </div>
    </div>
    <div class="is-flex is-justify-content-center">

      <div class="mx-5">
          <b-colorpicker value="#A09B9B" @input="updateBody" representation="square" position="is-top-left"/>
        <p class="has-text-weight-bold ">Body Color</p>
      </div>
      <div class="mx-5">
          <b-colorpicker value="#59585C" @input="updateRing" representation="square" position="is-top-left"/>
        <p class="has-text-weight-bold ">Cup Ring Color</p>
      </div>
      <div class="mx-5">
          <b-colorpicker value="#474350" @input="updateCups" representation="square" position="is-top-left"/>
        <p class="has-text-weight-bold ">Eye Cup Color</p>
      </div>
      <div class="mx-5">
          <b-colorpicker value="#706A59" @input="updateFocus" representation="square" position="is-top-left"/>
        <p class="has-text-weight-bold ">Focus Ring Color</p>
      </div>
      <div class="mx-5">
          <b-colorpicker value="#A5A58D" @input="updateBadge" representation="square" position="is-top-left"/>
        <p class="has-text-weight-bold ">Badge Color</p>
      </div>
      <div class="mx-5">
          <b-colorpicker value="#FFE8D6" @input="updateLogo" representation="square" position="is-top-left"/>
        <p class="has-text-weight-bold ">Logo Color</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      colors: "#A09B9B",
    };
  },

  methods: {
    updateBody(e) {
      this.$refs.bodyColor.style.fill = `rgba(${e.red},${e.green},${e.blue},${e.alpha})`;
    },
    updateRing(e) {
      this.$refs.ringColor.style.fill = `rgba(${e.red},${e.green},${e.blue},${e.alpha})`;
    },
    updateCups(e) {
      this.$refs.cupColor.style.fill = `rgba(${e.red},${e.green},${e.blue},${e.alpha})`;
    },
    updateFocus(e) {
      this.$refs.focusColor.style.fill = `rgba(${e.red},${e.green},${e.blue},${e.alpha})`;
    },
    updateBadge(e) {
      this.$refs.badgeColor.style.fill = `rgba(${e.red},${e.green},${e.blue},${e.alpha})`;
    },
        updateLogo(e) {
      this.$refs.logoColor.style.fill = `rgba(${e.red},${e.green},${e.blue},${e.alpha})`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.art-contain {
  width: 700px;
  height: 600px;
  margin: 1rem auto;
  position: relative;
}
.body-svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  svg {
    fill: #A09B9B;
  }
}
.cup-ring-svg {
  position: absolute;
  top: 119px;
  left: 280px;
  z-index: 5;

  svg {
    fill: #59585C;
  }
}
.cups-svg {
  position: absolute;
  top: 132px;
  left: 308px;
  z-index: 1;
  svg {
    fill: #474350;
  }
}

.icon-svg {
  position: absolute;
  top: 184px;
  left: 435px;
  z-index: 2;
  svg {
    fill: #A5A58D;
  }
}
.logo-svg {
  position: absolute;
  top: 202px;
  left: 435px;
  z-index: 7;
  svg {
    fill: #FFE8D6
  }
}

.focus-svg {
  position: absolute;
  top: 148px;
  left: 377px;
  z-index: 7;
  svg {
    fill: #706A59;
  }
}
.overlay-svg {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  opacity: 0.55;
}
</style>
